/* Vendor imports */
import React from 'react'
/* App imports */
import style from './success.module.less'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Button from '../../components/button'
import Config from '../../../config'
import Utils from '../../utils'

const NotFoundPage = ({ data }) => (
  <Layout>
    <SEO
      title="200: Message submitted successfully"
      description="Contact Success Page"
      path="/success"
      keywords={['javascript', 'devops', 'tutorial', 'tech']}
    />
    <div className={style.container}>
      <div className={style.message}>
      <h1>Your message was sent!</h1>
      <p>I'll answer your request as quick as possible.</p>
        <Button to={Utils.resolvePageUrl(Config.pages.home)}>
          Return to homepage
        </Button>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
